<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBCard class="m-3">
    <MDBCardBody>
      <transition name="fade">
        <PageLoading v-if="PageLoading"></PageLoading>
      </transition>
      <MDBTabs v-model="currentLanguage" v-if="!PageLoading">
        <MDBTabNav fill>
          <template v-for="(language, k) in Languages" :key="k">
            <MDBTabItem :tabId="language.lang">{{
              $t(`LANG.${language.lang}`)
            }}</MDBTabItem>
          </template>
        </MDBTabNav>
        <MDBTabContent>
          <template v-for="(language, k) in Languages" :key="k">
            <MDBTabPane :tabId="language.lang" class="pt-3">
              <MDBRow class="g-3">
                <MDBCol md="12">
                  <MDBCard class="m-12">
                    <MDBCardBody>
                        <input type="hidden" value="modelValue" />
                        <MDBRow>
                          <MDBCol md="12" class="mb-2">
                            <MDBCardTitle>固定模版</MDBCardTitle>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="12" class="mb-2">
                            <div v-html="template">
                            </div>
                          </MDBCol>
                        </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
              <template v-for="(item, index) in AllSettings[language.lang]" :key="index">
                <TemplateForm :data="item" :uuid="item.uuid"></TemplateForm>
              </template>
            </MDBTabPane>
          </template>
        </MDBTabContent>
      </MDBTabs>
    </MDBCardBody>
  </MDBCard>
</template>

<style scope>
.settings-img {
  width: 100%;
}
</style>

<script>
import {
  MDBTabs,
  MDBTabPane,
  MDBTabNav,
  MDBTabItem,
  MDBTabContent,
  MDBCol,
  MDBCardTitle,
  MDBCard,
  MDBCardBody,
  MDBRow,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import { ref, reactive } from "vue";
import i18nService from "@/core/services/i18n.service.js";
import PageLoading from "@/components/Elements/PageLoading";
import TemplateForm from "@/components/Form/Email/TemplateForm";

export default {
  name: "email-template",
  setup() {
    const Languages = i18nService.languages;
    const AllSettings = reactive({
      uuid: ref(""),
    });
    const template = ref("");
    const PageLoading = ref(true);
    const currentLanguage = ref(i18nService.getActiveLanguage());
    // 取得信件模板
    const GetSettings = async function () {
      return await ApiService.query(`/Admin/v1/email/template`).then((response) => {
        if (response.status == 200) {
          let data = response.data.data.data;
          if (data != null) {
            for (let i = Languages.length; i--; ) {
              AllSettings[Languages[i].lang] = langFilter(data, Languages[i].lang);
            }
          }
          template.value = response.data.data.template;
        }
      });
    };
    // 語系分類
    const langFilter = (data, lang) => {
      return data.filter((item) => item.lang.indexOf(lang) !== -1);
    };

    return {
      PageLoading, //頁面載入特效
      GetSettings,
      AllSettings,
      Languages, //語言列表
      currentLanguage, //當前語言
      template,
    };
  },
  components: {
    Breadcrumbs,
    MDBTabs,
    MDBTabPane,
    MDBTabNav,
    MDBTabItem,
    MDBTabContent,
    MDBCard,
    MDBCardBody,
    PageLoading,
    TemplateForm,
    MDBCol,
    MDBCardTitle,
    MDBRow,
  },
  async mounted() {
    Promise.all([await this.GetSettings()])
      .then(() => {
        this.PageLoading = false;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
