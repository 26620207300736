<template>
  <MDBRow class="g-3">
    <MDBCol md="12">
      <MDBCard class="m-12" v-if="Form.template_part.value === 0">
        <MDBCardBody>
          <form @submit="Submit">
            <input type="hidden" value="modelValue" />
            <MDBRow>
              <MDBCol md="12" class="mb-2">
                <MDBCardTitle>{{ Form.title.value }}</MDBCardTitle>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol md="7" class="mb-2">
                <MDBRow>
                  <MDBCol md="12">
                    <div>
                      <label class="form-label text-dark">{{
                        $t("EMAIL.TEMPLATE.SUBJECT")
                      }}</label>
                      <input type="text" class="form-control" v-model="Form.subject.value" />
                      <span class="text-danger">{{ subjectError }}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md="12">
                    <div>
                      <Ckeditor5
                        :label="$t('EMAIL.TEMPLATE.CONTENT')"
                        v-model="Form.content.value"
                      ></Ckeditor5>
                      <span class="text-danger">{{ contentError }}</span>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol md="5" class="mb-2">
                <label class="form-label text-dark">管理員密件副本</label>
                <template v-for="(item,k) in Form.cc.value" :key="k">
                  <input type="text" class="form-control mb-2" v-model="Form.cc.value[k]"/>
                </template>
                <span class="text-danger">{{ ccError }}</span>
              </MDBCol>
            </MDBRow>
            <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
              {{ $t("COMMON.SAVE") }}
              <span
                class="spinner-border Loading"
                :class="{ 'd-none': !SubmitLoading }"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </span>
            </MDBBtn>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
  <br />
</template>

<script>
import {
  MDBRow,
  MDBBtn,
  MDBCol,
  MDBCardTitle,
  MDBCard,
  MDBCardBody,
} from "mdb-vue-ui-kit";
import ApiService from "@/core/services/api.service";
import CommonService from "@/core/services/common.service";
import { useForm, useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { computed, ref } from "vue";
import Ckeditor5 from "@/components/Plugin/Ckeditor5";

export default {
  name: "EmailTemplateForm",
  components: {
    MDBRow,
    MDBBtn,
    MDBCol,
    MDBCardTitle,
    MDBCard,
    MDBCardBody,
    Ckeditor5,
  },
  props: {
    uuid: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const SubmitLoading = ref(false);
    const template_part = ref(0);
    const title = ref("");

    const basicSchema = {
      subject: yup.string().required().label(i18n.t("EMAIL.TEMPLATE.SUBJECT")),
      content: yup.string().required().label(i18n.t("EMAIL.TEMPLATE.CONTENT")),
      cc: yup.array().label("管理員密件副本"),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit,setFieldValue } = useForm({
      validationSchema: FormSchema,
    });

    const { value: subject, errorMessage: subjectError } = useField("subject");
    const { value: content, errorMessage: contentError } = useField("content");
    const { value: cc, errorMessage: ccError } = useField("cc");
    setFieldValue('cc',["","","","",""])
    // 資料送出
    const Submit = handleSubmit((values) => {
      SubmitLoading.value = true;
      ApiService.update("/Admin/v1/email/template", props.uuid, values).then(
        (response) => {
          if (response.status == 200) {
            CommonService.AxiosHandle(response);
            SubmitLoading.value = false;
          }
        }
      );
      return false;
    });

    const htmlDecode = (str) => {
      var ele = document.createElement("div");
      ele.innerHTML = str;
      return ele.textContent || ele.innerText;
    };

    const Form = {
      subject,
      content,
      template_part,
      title,
      cc,
    };

    const SettingsData = computed({
      get: () => props.data,
      set: (val) => emit("update:data", val),
    });

    for (let type in SettingsData.value) {
      if (SettingsData.value[type] != null && typeof Form[type] != "undefined") {
        const value = SettingsData.value[type];
        Form[type].value = ["subject", "content"].includes(type)
          ? htmlDecode(value)
          : value;
      }
    }

    return {
      Form,
      SubmitLoading,
      Submit,
      subjectError,
      contentError,
      ccError,
    };
  },
};
</script>
